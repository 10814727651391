<template>

  <div>
    <v-app>

      <!--        <v-btn
                  v-scroll="onScroll"
                  v-show="fab"
                  fab
                  dark
                  fixed
                  bottom
                  style="right: 100px !important;"
                  elevation="10"
                  color="primary"
                  @click="toTop"
              >
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>-->
      <div id="create">
        <v-speed-dial
            v-model="chatBtn"
            bottom
            v-scroll="onScroll"
            left
            fixed
            fab
            direction="top"
            open-on-hover
            transition="slide-y-reverse-transition"
        >
          <template v-slot:activator>
            <v-btn
                v-model="fab"
                color="blue darken-2"
                dark
                fab
            >
              <v-icon v-if="chatBtn">
                mdi-chat-processing-outline
              </v-icon>
              <v-icon v-else>
                mdi-chat-outline
              </v-icon>
            </v-btn>
          </template>
          <v-btn
              fab
              dark
              small
              target="_blank"
              :href="doc && doc.telegramUrl || ''"
              color="blue"
          >
            <v-icon>mdi-send</v-icon>
          </v-btn>
          <v-btn
              fab
              dark
              small
              :href="doc && doc.facebookUrl || ''"

              color="indigo"
          >
            <v-icon>mdi-facebook-messenger</v-icon>
          </v-btn>
          <v-btn
              fab
              dark
              @click="toTop"
              small
              color="green"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </v-speed-dial>
      </div>


      <v-app-bar
          fixed
          :color="elv===0 ? 'd' :'white !important'"
          light
          :style="$vuetify.breakpoint.mobile ? 'background-color: transparent;' : 'background-color: transparent;padding-left: 5%'"
          :elevation="elv"

      >
        <div style="vertical-align: middle">
          <img @click="goToHome" src="@/assets/logo.png" height="70px" style="float:left;cursor: pointer"/>
          <v-tab>
            <span v-if="!$vuetify.breakpoint.mobile"
                  style="font-weight: bold;font-size: 25px;padding-top: 25px;font-family: 'Khmer Kompong Trach';color: green">Green House</span>
            <span v-if="$vuetify.breakpoint.mobile"
                  style="font-weight: bold;font-size: 12px;padding-top: 25px;font-family: 'Khmer Kompong Trach';color: green">Green House</span>

          </v-tab>
        </div>
        <tab-list :style="elv===0 ? 'left: 25%;width: 75%' : 'left:27%;width:75%'"
                  v-if="!$vuetify.breakpoint.mobile"></tab-list>

        <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>
        <!--        <v-text-field :placeholder="$t('search')" filled dense prepend-inner-icon="mdi-magnify"
                              class="mt-7 mr-5 expanding-search"
                              @focus="searchClose=false"
                              @blur="searchClose=true"
                              v-model="search"
                              clearable
                              :class="{'close' : searchClose}"
                ></v-text-field>-->
        <v-btn icon class="mx-4 text--darken-1" style="margin-left: 0px !important;margin-right: 0px !important;"
               @click="goToAllPlant()">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-menu offset-y open-on-hover >
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined class="mx-4 black--text" style="margin-left: 0px !important;margin-right: 0px !important;"
                   v-bind="attrs"
                   v-on="on">
              {{ languageLabel }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group
                color="primary"
            >
              <v-list-item
                  v-for="(item, index) in $vuetify.breakpoint.mobile ? languageListShort : languageList"
                  :key="index"
                  @click="changeLanguage(item)"
              >
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <v-btn v-if="$vuetify.breakpoint.mobile" icon class="mx-4 text--darken-1"
               style="margin-left: 0px !important;margin-right: 0px !important;"
               @click="drawer=!drawer"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>

        <!--      <v-menu offset-y v-if="$vuetify.breakpoint.mobile">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon class="mx-4 text&#45;&#45;darken-1" style="margin-left: 0px !important;margin-right: 0px !important;"
                         v-bind="attrs"
                         @click="drawer=!drawer"
                         v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group
                      color="primary"
                  >
                    <v-list-item
                        v-for="(item, index) in tabList"
                        :key="index"
                        :to="item.to"
                    >
                      <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>-->
      </v-app-bar>
      <!--      <div class="deco-banner container" style="top: 0">
              <div class="artwork-bg" v-if="!$vuetify.breakpoint.mobile">
              </div>
              <div class="artwork-bg1" v-if="$vuetify.breakpoint.mobile">
              </div>
            </div>-->

      <v-main style="min-height: 1400px;">
        <!--        <Banner1 style="padding-top: 70px"></Banner1>-->

        <router-view/>


        <Footer :data-footer="doc"></Footer>

        <!--      <div class="ocean" v-if="!$vuetify.breakpoint.mobile">
                <div class="wave"></div>
                <div class="wave"></div>
              </div>-->
        <!--      <div class="elfsight-app-80c0e95b-fbbe-45f4-a85f-28c8899c45a8"></div>-->
      </v-main>

      <v-navigation-drawer
          v-model="drawer"
          top
          fixed
          v-if="$vuetify.breakpoint.mobile"
          temporary
      >
        <v-list-item>
          <v-list-item-avatar>
            <img src="@/assets/logo.png" height="100" width="100"/>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title style="font-family: 'Apple Chancery';font-weight: bold">Green House</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="text-align: left;">{{ $t('home') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
              prepend-icon="mdi-sprout"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('plantType') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="(item,i) in plantTypeList"
                :key="i"
                :to="{name:'plant',params:{title:getTranslate(item.title),id:item._id,type:item.type,doc:item}}"

            >
              <v-list-item-content>
                <v-list-item-title style="text-align: left" v-html="getTranslate(item.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
              prepend-icon="mdi-spa"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('plantByLifeStyle') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="(item,i) in plantLifeStyleList"
                :to="{name:'plant',params:{title:getTranslate(item.title),id:item._id,type:item.type,doc:item}}"

                :key="i"
            >
              <v-list-item-content>
                <v-list-item-title style="text-align: left" v-html="getTranslate(item.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
              prepend-icon="mdi-gift"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('plantGifts') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="(item,i) in plantGiftList"
                :key="i"
                :to="{name:'plant',params:{title:getTranslate(item.title),id:item._id,type:item.type,doc:item}}"

            >
              <v-list-item-content>
                <v-list-item-title style="text-align: left" v-html="getTranslate(item.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-group
              prepend-icon="mdi-home-group"
              no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('shopByRoom') }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
                v-for="(item,i) in plantRoomList"
                :key="i"
                :to="{name:'plant',params:{title:getTranslate(item.title),id:item._id,type:item.type,doc:item}}"

            >
              <v-list-item-content>
                <v-list-item-title style="text-align: left" v-html="getTranslate(item.title)"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item to="/about">
            <v-list-item-icon>
              <v-icon>mdi-information-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="text-align: left">{{ $t('about') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/contact">
            <v-list-item-icon>
              <v-icon>mdi-book-open-blank-variant</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title style="text-align: left">{{ $t('contact') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

    </v-app>
  </div>
</template>

<script>
import Footer from "@/views/layout/footer";
//import Banner1 from "@/views/layout/banner";
import {Constants} from "@/libs/constant";
import TabList from "@/components/tabList";
import axios from "axios";
import MyMixin from "@/mixins/mixin"

export default {
  name: 'App',
  components: {Footer, TabList},
  comments: {Footer},
  mixins: [MyMixin],

  data() {
    return {
      socialList: Constants.socialLink,
      languageList:  Constants.languageList,
      languageListShort:  Constants.languageListShort,
      tabList: Constants.tabList,
      searchClose: true,
      languageLabel: "En",
      fab: false,
      chatBtn: false,
      drawer: false,
      elv: 0,
      doc: {},
      plantTypeList: [],
      plantRoomList: [],
      plantLifeStyleList: [],
      plantGiftList: []
    }
  },
  methods: {
    goToAllPlant(filter, type) {
      //:to="{name:'plant',params:{title:getTranslate(d.title),id:d._id,type:d.type,doc:d}}"
      let vm = this;
      vm.$router.replace({
        name: 'allPlant',
        params: {title: "All Plant", filter: filter, type: type}
      });
    },
    goToHome() {
      let vm = this;
      vm.$router.push({
        name: "Home"
      })
    },
    changeLanguage(doc) {
      let vm = this;
      vm.$i18n.locale = doc.value;
      vm.languageLabel = doc.name;
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20
      this.elv = top > 20 ? 20 : 0;
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    fetchTab() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getTab;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          addToHome: false
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.plantTypeList = res.data.data.plantType;
                vm.plantRoomList = res.data.data.plantRoom;
                vm.plantLifeStyleList = res.data.data.plantLifeStyle;
                vm.plantGiftList = res.data.data.plantGift;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    fetchHeaderFooter() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getHeaderFooter;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.doc = res.data.data;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    this.fetchHeaderFooter();
    if (this.$vuetify.breakpoint.mobile) {
      this.fetchTab();
    }
  }
};

</script>

<style lang="scss">

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.fixTitle .v-toolbar__content {
  height: 40px !important;
}


.sub-1 {
  text-align: justify;
  font-family: "Khmer Kompong Trach" !important;
}

* {
  font-family: "Khmer Kompong Trach";
}

.hover02 figure img {
  width: 95px;
  height: auto;
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}

.hover02 figure:hover img {
  width: 140px;
}

figure {
  width: 100px;
  height: 130px;
  margin: 0;
  padding: 0;
  background: #fff;
  overflow: hidden;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: #2b2e2e;
}


.ten_tagline:after, .ten_tagline--fill:after {
  content: "";
  display: block;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: calc(100% - 12px);
  border-top: 1px solid rgba(25, 74, 195, 1);
}

.ten_tagline:before, .ten_tagline--fill:before {
  content: "";
  display: block;
  position: absolute;
  left: calc(100% - 12px);
  bottom: 0px;
  width: 100%;
  border-top: 1px solid rgba(25, 74, 195, 1);
  transform: rotate(
          -69deg) translate(-1px, 0px);
  transform-origin: 0px 0px;
}

.ten_tagline, .ten_tagline--fill {
  font-family: 'TencentSansw7';
  overflow: hidden;
  font-size: 14px;
  line-height: 24px;
  height: 30px;
  display: inline-block;
  position: relative;
  border-top: 1px solid rgba(25, 74, 195, 1);
  padding: 0px 30px;
  color: rgba(25, 74, 195, 1);
}

.ten_tagline {
  border-left: 1px solid rgba(25, 74, 195, 1);
}

.bBottom {
  border-bottom: 1px solid #f4f4f4;
}

.deco-banner .artwork-bg {
  position: absolute !important;
  top: -420px;
  border-radius: 40px;
  transform: rotate(-10deg);
  width: 120%;
  overflow: hidden;
  height: 1024px;
  background-color: lightblue;
}

.deco-banner .artwork-bg1 {
  position: absolute !important;
  top: -420px;
  width: 100%;
  left: 0px;
  right: 0px;
  overflow: hidden;
  height: 1024px;
  background-color: lightblue;
}


// best seen at 1500px or less

/*body {
  background:radial-gradient(ellipse at center, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #B7E8EB 100%);
}*/

.ocean {
  height: 0%;
  width: 100%;
  position: absolute;
  bottom: 50px;
  left: 0;
  background: #015871;
}

.wave {
  background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/85486/wave.svg) repeat-x;
  position: absolute;
  top: -198px;
  width: 6400px;
  height: 180px;
  animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
  top: -175px;
  animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}

@keyframes swell {
  0%, 100% {
    transform: translate3d(0, -25px, 0);
  }
  50% {
    transform: translate3d(0, 5px, 0);
  }
}

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.section_tittle h2:after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  width: 80px;
  height: 3px;
  background-color: green;
}

.row {
  margin: 0px !important;
}

/*#create .v-speed-dial {
  position: absolute;
}*/

/*#create .v-btn--floating {
  position: relative;
}*/


@font-face {
  font-family: "Khmer Kompong Trach";
  src: url(assets/font/KHMKAMPONGTRACH.ttf);
}

.v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
  line-height: 1.9 !important;
}

.expanding-search > .v-input__control > .v-input__slot:before {
  border-color: transparent !important;
}

.expanding-search > .v-input__control > .v-input__slot:after {
  border-color: transparent !important;
  transform: scale(0) !important;
}

.close {
  max-width: 45px !important;
}


</style>
