var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.$vuetify.breakpoint.mobile)?_c('v-tabs',{staticStyle:{"position":"absolute","font-family":"'Khmer Kompong Trach'"}},[_c('v-tab',{staticStyle:{"font-size":"14px"},attrs:{"to":"/"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('home')))])])]),(_vm.plantTypeList.length>0)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"font-size":"14px"},attrs:{"to":{name:'plant',params:{title:_vm.getTranslate(_vm.plantTypeList[0].title),id:_vm.plantTypeList[0]._id,type:_vm.plantTypeList[0].type,doc:_vm.plantTypeList[0]}}}},'v-tab',attrs,false),on),[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('plantType')))])])])]}}],null,false,3881670751)},[_c('v-list',{staticClass:"column_wrapper"},_vm._l((_vm.plantTypeList),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"min-height":"0px","padding-top":"12px !important","padding-right":"25px !important"},attrs:{"to":{name:'plant',params:{title:_vm.getTranslate(item.title),id:item._id,type:item.type,doc:item}},"link":""}},[_c('v-list-item-title',{staticStyle:{"text-align":"left !important"},domProps:{"innerHTML":_vm._s(_vm.getTranslate(item.title))}})],1)}),1)],1):_vm._e(),(_vm.plantLifeStyleList.length>0)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"font-size":"14px"},attrs:{"to":{name:'plant',params:{title:_vm.getTranslate(_vm.plantLifeStyleList[0].title),id:_vm.plantLifeStyleList[0]._id,type:_vm.plantLifeStyleList[0].type,doc:_vm.plantLifeStyleList[0]}}}},'v-tab',attrs,false),on),[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('plantByLifeStyle')))])])])]}}],null,false,2603996461)},[_c('v-list',{staticClass:"column_wrapper1"},_vm._l((_vm.plantLifeStyleList),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"min-height":"0px","padding-top":"12px !important","padding-right":"25px !important"},attrs:{"to":{name:'plant',params:{title:_vm.getTranslate(item.title),id:item._id,type:item.type,doc:item}},"link":""}},[_c('v-list-item-title',{staticStyle:{"text-align":"left !important"},domProps:{"innerHTML":_vm._s(_vm.getTranslate(item.title))}})],1)}),1)],1):_vm._e(),(_vm.plantGiftList.length>0)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"font-size":"14px"},attrs:{"to":{name:'plant',params:{title:_vm.getTranslate(_vm.plantGiftList[0].title),id:_vm.plantGiftList[0]._id,type:_vm.plantGiftList[0].type,doc:_vm.plantGiftList[0]}}}},'v-tab',attrs,false),on),[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('plantGifts')))])])])]}}],null,false,2682100840)},[_c('v-list',{staticClass:"column_wrapper1"},_vm._l((_vm.plantGiftList),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"min-height":"0px","padding-top":"12px !important","padding-right":"25px !important"},attrs:{"to":{name:'plant',params:{title:_vm.getTranslate(item.title),id:item._id,type:item.type,doc:item}},"link":""}},[_c('v-list-item-title',{staticStyle:{"text-align":"left !important"},domProps:{"innerHTML":_vm._s(_vm.getTranslate(item.title))}})],1)}),1)],1):_vm._e(),(_vm.plantRoomList.length>0)?_c('v-menu',{attrs:{"open-on-hover":"","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-tab',_vm._g(_vm._b({staticStyle:{"font-size":"14px"},attrs:{"to":{name:'plant',params:{title: _vm.getTranslate(_vm.plantRoomList[0].title),id:_vm.plantRoomList[0]._id,type:_vm.plantRoomList[0].type,doc:_vm.plantRoomList[0]}}}},'v-tab',attrs,false),on),[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('shopByRoom')))])])])]}}],null,false,4118502560)},[_c('v-list',{staticClass:"column_wrapper1"},_vm._l((_vm.plantRoomList),function(item,i){return _c('v-list-item',{key:i,staticStyle:{"min-height":"0px","padding-top":"12px !important","padding-right":"25px !important"},attrs:{"to":{name:'plant',params:{title: _vm.getTranslate(item.title),id:item._id,type:'Plant Room',doc:item}},"link":""}},[_c('v-list-item-title',{staticStyle:{"text-align":"left !important"},domProps:{"innerHTML":_vm._s(_vm.getTranslate(item.title))}})],1)}),1)],1):_vm._e(),_c('v-tab',{staticStyle:{"font-size":"14px"},attrs:{"to":"/about"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('about')))])])]),_c('v-tab',{staticStyle:{"font-size":"14px"},attrs:{"to":"/contact"}},[_c('div',[_c('b',[_vm._v(_vm._s(_vm.$t('contact')))])])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }