<template>
  <v-footer
      light
      padless
      style="background-color: grey !important;"
  >
    <!--    style="background-color: #244a93 !important;"-->

    <!--      style="background-color: #455A64 !important;"-->

    <v-card
        flat
        tile
        style="background-image: url('https://firebasestorage.googleapis.com/v0/b/greenhouse-6f31c.appspot.com/o/GH-Webcover-1-min.jpeg?alt=media&token=3640833c-6056-455a-8bcb-bdc5b5fb2c63');background-size: cover; width: 100%;"

        class="green darken-2 white--text text-center"
    >
      <v-sheet
          :color="colors"
          height="103%"
      >
        <v-card-text class="white--text pt-0">
          <v-row :style="$vuetify.breakpoint.mobile ? '' : 'padding-left:10%;padding-right:10%'">
            <v-col cols="12" md="4" sm="4" style="text-align: left">
              <h1 style="padding-top: 15px;font-family: 'Apple Chancery'">{{ $t('followUsOnFacebook') }}</h1>
              <br>
              <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fweb.facebook.com%2Fgreenhouse.kh&tabs=timeline&width=300&height=400&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=749415358997920" width="300" height="400" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </v-col>
            <v-col cols="12" md="5" sm="5" style="text-align: left">
              <h1 style="padding-top: 15px;padding-left: 30px;font-family: 'Apple Chancery'">{{ $t('contactUs') }}</h1>
              <br>
              <v-card-text>
                <v-list-item
                    dark
                >
                  <v-list-item-icon style="margin-top: 0px !important;margin-bottom: 0px !important;">
                    <v-btn x-small fab color="success">
                      <v-icon>mdi-map-search</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-title style="text-align: left"
                                     v-html="getTranslate(dataFooter && dataFooter.addressFooter || '')"></v-list-item-title>
                </v-list-item>
                <v-list-item
                    dark
                >
                  <v-list-item-icon style="margin-top: 0px !important;margin-bottom: 0px !important;">
                    <v-btn x-small fab color="success">
                      <v-icon>mdi-phone</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-title style="text-align: left"
                                     v-html="dataFooter && dataFooter.phoneNumberFooter || ''"></v-list-item-title>
                </v-list-item>
                <v-list-item
                    dark
                    target="_blank"
                    :href="dataFooter.telegramUrl"
                    style="min-height: 32px !important;padding-bottom: 16px"
                >
                  <v-list-item-icon style="margin-top: 0px !important;margin-bottom: 0px !important;">
                    <v-btn x-small fab color="blue">
                      <v-icon>mdi-send-circle</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-title style="text-align: left;"
                                     v-html="dataFooter && dataFooter.telegramUrl || ''"></v-list-item-title>
                </v-list-item>
                <v-list-item
                    dark
                >
                  <v-list-item-icon style="margin-top: 0px !important;margin-bottom: 0px !important;">
                    <v-btn x-small fab color="primary">
                      <v-icon>mdi-email</v-icon>
                    </v-btn>
                  </v-list-item-icon>
                  <v-list-item-title style="text-align: left"
                                     v-html="dataFooter && dataFooter.emailFooter || ''"></v-list-item-title>
                </v-list-item>
              </v-card-text>
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <h1 style="padding-top: 15px;font-family: 'Apple Chancery'">{{ $t('siteMap') }}</h1>
              <br>
              <v-card-text>
                <v-list-item
                    v-for="(item, index) in tabList"
                    :key="index"
                    :to="item.to"
                    dark
                >
                  <v-list-item-title>{{ $t(item.name) }}</v-list-item-title>
                </v-list-item>
                <!--                <v-btn
                                    class="mx-4 white&#45;&#45;text"
                                    icon
                                    :href="dataFooter && dataFooter.facebookUrl || ''"
                                    target="_blank"
                                >
                                  <v-icon size="24px">
                                    mdi-facebook
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                    class="mx-4 white&#45;&#45;text"
                                    icon
                                    :href="dataFooter && dataFooter.twitterUrl || ''"
                                    target="_blank"
                                >
                                  <v-icon size="24px">
                                    mdi-twitter
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                    class="mx-4 white&#45;&#45;text"
                                    icon
                                    :href="dataFooter && dataFooter.linkInUrl || ''"
                                    target="_blank"
                                >
                                  <v-icon size="24px">
                                    mdi-linkedin
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                    class="mx-4 white&#45;&#45;text"
                                    icon
                                    :href="dataFooter && dataFooter.instagramUrl || ''"
                                    target="_blank"
                                >
                                  <v-icon size="24px">
                                    mdi-instagram
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                    class="mx-4 white&#45;&#45;text"
                                    icon
                                    :href="dataFooter && dataFooter.telegramUrl || ''"
                                    target="_blank"
                                >
                                  <v-icon size="24px">
                                    mdi-send
                                  </v-icon>
                                </v-btn>-->
              </v-card-text>
            </v-col>
          </v-row>
        </v-card-text>


        <v-divider></v-divider>
      </v-sheet>
    </v-card>


    <v-card-text class="white--text">
      {{ new Date().getFullYear() }} - <strong>
      <v-icon style="color: white">mdi-copyright</v-icon>
      Green House Cambodia</strong>
    </v-card-text>
  </v-footer>
</template>

<script>
import {Constants} from "@/libs/constant";
import MyMixin from "@/mixins/mixin"

export default {
  props: ["dataFooter"],
  mixins: [MyMixin],

  data: () => ({
    socialList: [],
    phoneNumberList: Constants.phoneNumberList,
    tabList: Constants.tabList,
    missionList: Constants.missionList,
    colors: "rgb(0,128,0,0.7)"
    // colors: "rgb(36, 74, 147,0.7)",
    // colors: "#244a93",
    // colors: "rgb(14, 64, 106,0.7)",
  })
}
</script>

<style lang="scss" scoped>
.v-application a {
  color: aliceblue !important;
}
</style>