<template>
  <v-tabs style="position: absolute;font-family:'Khmer Kompong Trach';"
          v-if="!$vuetify.breakpoint.mobile">
    <v-tab to="/" style="font-size: 14px">
      <div><b>{{ $t('home') }}</b></div>
    </v-tab>
    <v-menu
        open-on-hover
        rounded
        offset-y
        v-if="plantTypeList.length>0"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-tab
            :to="{name:'plant',params:{title:getTranslate(plantTypeList[0].title),id:plantTypeList[0]._id,type:plantTypeList[0].type,doc:plantTypeList[0]}}"
            v-bind="attrs"
            style="font-size: 14px"
            v-on="on">
          <div><b>{{ $t('plantType') }}</b></div>
        </v-tab>
      </template>
      <v-list class="column_wrapper">
        <v-list-item
            v-for="(item,i) in plantTypeList"
            :key="i"
            :to="{name:'plant',params:{title:getTranslate(item.title),id:item._id,type:item.type,doc:item}}"
            style="min-height: 0px;padding-top: 12px !important;padding-right: 25px !important;"
            link
        >
          <v-list-item-title style="text-align: left !important;" v-html="getTranslate(item.title)"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
        open-on-hover
        rounded
        offset-y
        v-if="plantLifeStyleList.length>0"

    >
      <template v-slot:activator="{ attrs, on }">
        <v-tab
            :to="{name:'plant',params:{title:getTranslate(plantLifeStyleList[0].title),id:plantLifeStyleList[0]._id,type:plantLifeStyleList[0].type,doc:plantLifeStyleList[0]}}"
            v-bind="attrs"
            style="font-size: 14px"
            v-on="on">
          <div><b>{{ $t('plantByLifeStyle') }}</b></div>

        </v-tab>
      </template>
      <v-list class="column_wrapper1">
        <v-list-item
            v-for="(item,i) in plantLifeStyleList"
            :key="i"
            :to="{name:'plant',params:{title:getTranslate(item.title),id:item._id,type:item.type,doc:item}}"
            style="min-height: 0px;padding-top: 12px !important;padding-right: 25px !important;"
            link
        >
          <v-list-item-title style="text-align: left !important;" v-html="getTranslate(item.title)"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
        open-on-hover
        rounded
        offset-y
        v-if="plantGiftList.length>0"

    >
      <template v-slot:activator="{ attrs, on }">
        <v-tab
            :to="{name:'plant',params:{title:getTranslate(plantGiftList[0].title),id:plantGiftList[0]._id,type:plantGiftList[0].type,doc:plantGiftList[0]}}"
            v-bind="attrs"
            style="font-size: 14px"
            v-on="on">
          <div><b>{{ $t('plantGifts') }}</b></div>

        </v-tab>
      </template>
      <v-list class="column_wrapper1">
        <v-list-item
            v-for="(item,i) in plantGiftList"
            :key="i"
            :to="{name:'plant',params:{title:getTranslate(item.title),id:item._id,type:item.type,doc:item}}"
            style="min-height: 0px;padding-top: 12px !important;padding-right: 25px !important;"
            link
        >
          <v-list-item-title style="text-align: left !important;" v-html="getTranslate(item.title)"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
        open-on-hover
        rounded
        offset-y
        v-if="plantRoomList.length>0"

    >
      <template v-slot:activator="{ attrs, on }">
        <v-tab
            :to="{name:'plant',params:{title: getTranslate(plantRoomList[0].title),id:plantRoomList[0]._id,type:plantRoomList[0].type,doc:plantRoomList[0]}}"
            v-bind="attrs"
            style="font-size: 14px"
            v-on="on">
          <div><b>{{ $t('shopByRoom') }}</b></div>
        </v-tab>
      </template>
      <v-list class="column_wrapper1">
        <v-list-item
            v-for="(item,i) in plantRoomList"
            :key="i"
            :to="{name:'plant',params:{title: getTranslate(item.title),id:item._id,type:'Plant Room',doc:item}}"
            style="min-height: 0px;padding-top: 12px !important;padding-right: 25px !important;"
            link
        >
          <v-list-item-title style="text-align: left !important;" v-html="getTranslate(item.title)"></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-tab to="/about"  style="font-size: 14px">
      <div><b>{{ $t('about') }}</b></div>
    </v-tab>
    <v-tab to="/contact"  style="font-size: 14px">
      <div><b>{{ $t('contact') }}</b></div>
    </v-tab>
  </v-tabs>
</template>
<script>
import {Constants} from "@/libs/constant"
import axios from "axios";
import MyMixin from "@/mixins/mixin"

export default {
  name: "TabList",
  mixins: [MyMixin],

  data() {
    return {
      plantTypeList: [],
      plantRoomList: [],
      plantLifeStyleList: [],
      plantGiftList: []
    }
  },
  methods: {
    fetchTab() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getTab;
      return new Promise((resolve, reject) => {
        axios.post(url, {
          branchId: Constants.branchId,
          addToHome: false
        }, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.plantTypeList = res.data.data.plantType;
                vm.plantRoomList = res.data.data.plantRoom;
                vm.plantLifeStyleList = res.data.data.plantLifeStyle;
                vm.plantGiftList = res.data.data.plantGift;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    let vm = this;
    vm.fetchTab();
  }
}
</script>

<style lang="scss" scoped>
.column_wrapper {
  column-count: 3;
}
.column_wrapper1 {
  column-count: 2;
}
</style>