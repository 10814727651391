
let TabList=[
    {name:"home",to:"/"},
    {name:"allPlant",to:"/allPlant/All Plant"},
    {name:"about",to:"/about"},
    {name:"contact",to:"/contact"}
]
let LanguageList=[
    {name:"ភាសារខ្មែរ",value:"km"},
    {name:"English",value:"en"},
    {name:"中文",value:"cn"}
]
let LanguageListShort=[
    {name:"ខ្មែរ",value:"km"},
    {name:"En",value:"en"},
    {name:"中文",value:"cn"}
]
let MissionList=[
    {title:"mission1"},
    {title:"mission2"},
    {title:"mission3"},
    {title:"mission4"},
    {title:"mission5"}
]
let PhoneNumberList=[
    "010 480 762" ,
    "012 829 077" ,
    "087 985 282",
    "077 420 492"
]



export const Constants={
    languageList:LanguageList,
    languageListShort:LanguageListShort,
    tabList:TabList,

    missionList:MissionList,
    phoneNumberList:PhoneNumberList,
    token:"narongwebsite",
    baseURL:"https://entry.greenhousecambodia.com/api/v1/",
    //baseURL:"http://172.104.54.34:7683/api/v1/",
    branchId:"ceQQJKttstreTKg6X",

    /*baseURL:"http://localhost:3000/api/v1/",
    branchId:"ceQQJKttstreTKg6X",*/
    api:{
        getHome:"home/fetch",
        getTab:"tab/fetch",

        getAbout:"about/fetch",

        getContact:"contact/fetch",
        getProduct:"product/fetch",
        topTrendProduct:"topTrendProduct/fetch",
        viewProduct:"product/view",
        getProductWithFilter:"productWithFilter/fetch",

        getHeaderFooter:"headerFooter/fetch",
    },

}